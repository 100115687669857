* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: transparent;
    font-weight: normal;
    text-decoration: none;
    box-sizing: border-box;
    outline: none;
}
ol,
ul {
    list-style: none;
}
blockquote {
    quotes: none;
}
blockquote:before,
blockquote:after {
    content: "";
    content: none;
}
del {
    text-decoration: line-through;
}
header,
section,
article,
aside,
nav,
footer,
address {
    display: block;
}
body {
    font-family: "nunitoregular", Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #555;
    min-width: 320px;
    overflow-x: hidden;
}
input,
textarea,
select {
    font-family: Arial, Helvetica, sans-serif;
}
a {
    color: #555;
}
a:hover,
.submit:hover {
    filter: alpha(opacity = 85);
    -moz-opacity: 0.85;
    -khtml-opacity: 0.85;
    opacity: 0.85;
}
.thumb {
    display: block;
}
.thumb img {
    display: block;
    width: 100%;
}
p {
    line-height: 1.6em;
}
.hidden {
    display: none;
}
.left {
    float: left;
}
.right {
    float: right;
}
br.clear {
    clear: both;
    line-height: 0;
}
.wrapper {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}
@font-face {
    font-family: Inter-Bold;
    src: url("../fonts/Inter-Bold.ttf");
}
@font-face {
    font-family: Inter-Light;
    src: url("../fonts/Inter-Light.ttf");
}
@font-face {
    font-family: Inter-Medium;
    src: url("../fonts/Inter-Medium.ttf");
}
@font-face {
    font-family: Inter-Regular;
    src: url("../fonts/Inter-Regular.ttf");
}
@font-face {
    font-family: Inter-Thin;
    src: url("../fonts/Inter-Thin.ttf");
}
@font-face {
    font-family: Gordita-Medium;
    src: url("../fonts/Gordita-Medium.woff");
}

@font-face {
    font-family: Gordita-Regular;
    src: url("../fonts/Gordita-Regular.woff");
}

#main {
    padding: 88px 32px 32px 292px;
    min-height: 100vh;
    background-color: #e8f5f0;
}
@media all and (max-width: 1380px) {
    #main {
        padding: 88px 32px 32px 100px;
    }
}

@media all and (max-width: 1280px) {
    #main {
        padding: 88px 32px 32px 100px;
    }
}
@media all and (max-width: 1080px) {
    #main {
        padding: 70px 25px 25px 90px;
    }
}

@media all and (max-width: 768px) {
    /* #main {
        padding: 88px 32px 32px 100px;
    } */
}
@media all and (max-width: 640px) {
    #main {
        padding: 70px 15px 15px 15px;
    }
}
@media all and (max-width: 480px) {
    #main {
        padding: 88px 20px 32px 20px;
    }
}

#main .inner-wrapper {
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-gap: 22px;
}
@media all and (max-width: 1500px) {
    #main {
        padding: 88px 32px 32px 100px;
    }
}
@media all and (max-width: 1450px) {
    #main {
        padding: 88px 32px 32px 100px;
    }
}
@media all and (max-width: 1280px) {
    #main .inner-wrapper {
        grid-template-columns: none;
        flex-wrap: wrap;
    }
}
@media all and (max-width: 1180px) {
    #main .inner-wrapper {
        grid-template-columns: none;
    }
}
@media all and (max-width: 1080px) {
    #main .inner-wrapper {
        grid-template-columns: none;
    }
}
@media all and (max-width: 980px) {
    #main .inner-wrapper {
        grid-template-columns: none;
    }
}

@media all and (max-width: 768px) {
    #main {
        padding: 88px 16px 32px 88px;
    }
    #main .inner-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
}

@media all and (max-width: 640px) {
    #main {
        padding: 80px 20px 20px 20px;
    }
    #main .inner-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
}
@media all and (max-width: 480px) {
    #main {
        padding: 70px 10px 15px 10px;
    }
    #main .inner-wrapper {
        justify-content: space-around;
    }
}

/*-----      Pagination       -----*/
.pagination {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}
.pagination .pagination__break__li,
.pagination .pagination__li {
    border: 1px solid #f4f4f4;
    border-radius: 8px;
    cursor: pointer;
}
.pagination .pagination__li a,
.pagination .pagination__break__a {
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #333;
    width: 35px;
    height: 35px;
    display: block;
}
.pagination .pagination__li__active {
    background: #0fa76f;
    box-shadow: 0px 8px 9px rgba(15, 167, 111, 0.29);
    border-radius: 8px;
}
.pagination .pagination__li__active a {
    color: #fff;
}

.pagination .pagination__next__li,
.pagination .pagination__previous__li {
    border: 1px solid #0fa76f;
    border-radius: 8px;
}
.pagination .pagination__previous__li .pagination__previous__a,
.pagination .pagination__next__li .pagination__next__a {
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #333;
    width: 35px;
    height: 35px;
    display: block;
    cursor: pointer;
}
.pagination .pagination_disabled_btns {
    border: 1px solid #0fa76f;
    opacity: 0.4;
}
